<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="role"  label-width="100px">
      <el-form-item label="角色名称"  :required="true">
        <el-input type="text"  v-model="role.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="排序"  :required="true">
        <el-input type="number"  v-model="role.sort" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="授权模块">
        <el-tree
            ref="tree"
            :data="modules"
            show-checkbox
            node-key="id"
            :check-strictly="true"
            :default-checked-keys="checkedKeys"
            :props="defaultProps">
        </el-tree>
      </el-form-item>
      <el-form-item style="display: none">
        <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  TipEnum,
  UrlEnum,
  BranchEnum,
  request, getLocalProjectId,
} from "../../../public/js/common-vue";
import Loading from "@/components/Loading";

export default {
  name: "RoleSave",
  data() {
    return {
      loading: false,
      id:0,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      modules:[],
      checkedKeys:[],
      role:{
        name:'',
        sort:'',
        branchId:BranchEnum.FEN_XIAO,
        projectId:getLocalProjectId()
      }
    }
  },
  components: {Loading},
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id = request('id');
      this.initData();
    }
    else{
      this.getModules(this.role.branchId);
    }
  },
  methods:{
    getSelModuleIds() {
      let moduleIds = [];
      let nodes = this.$refs.tree.getCheckedNodes(false,true);
      for(var i=0;i<nodes.length;i++){
        moduleIds[i] = nodes[i].id;
      }
      return moduleIds;
    },
    getModules(branchId){
      this.$http({
        method: 'GET',
        url: UrlEnum.ROLE_MODULES+"?projectId="+getLocalProjectId()+"&branchId="+branchId,
        data: '',
      })
      .then((res) => {
        let modules = res.data.list;
        this.modules = modules;
      })
      .catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    submit:function (){
      if(this.role.name == ''){
        this.showMsgError("请填写角色名称")
        return;
      }
      if(this.role.sort == ''){
        this.showMsgError("请填写排序")
        return;
      }
      this.role.moduleIds = this.getSelModuleIds();
      this.loading = true;
      let url = UrlEnum.ROLES;
      let method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }
      this.$http({
        method: method,
        url: url,
        data: this.role,
      })
          .then((res) => {
            this.loading=false;
            this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
            setTimeout(function () {
              window.parent.postMessage({
                msg: '',
                key: 'refresh'
              }, '*')
            }, 800);
          })
          .catch((res) => {
            this.loading=false;
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
    },
    initData(){
      this.loading = true;
      this.$http({
        method: 'GET',
        url: UrlEnum.ROLES + "/" + request('id')+"?projectId="+getLocalProjectId(),
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.role = res.data;
        this.role.projectId=getLocalProjectId();
        let modules = this.role.modules;
        let moduleIds = [];
        for(let i=0; i< modules.length; i++){
          moduleIds[i] = modules[i].id;
        }
        this.checkedKeys = moduleIds;
        if(this.role.branchId == BranchEnum.ZONG_XIAO){
          this.getModules(BranchEnum.ZONG_XIAO);
        }
        else{
          this.getModules(BranchEnum.FEN_XIAO);
        }
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
  }
}
</script>

<style scoped>
</style>
